import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";
import {
    makeNetworkCallForAddQuestions,
  makeNetworkCallGetAllQuestion,
  makeNetworkCallGetQuestionById,
} from "../redux/action/action";
import { BlueLoader, inputPropsStyle } from "../util/AppUtils";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import Checkbox from "@mui/material/Checkbox";
import Stack from "@mui/material/Stack";
import LoadingButton from "@mui/lab/LoadingButton";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Fab, Tooltip } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import {
  DataStoreKey,
  setDataStoreItem,
} from "../persistence/PersistenceStorage";
import { NetworkConstant } from "../network/NetworkConstant";
import { ActionType } from "../redux/action/actionType";
import "./QuickAddQuestion.css";
import { QuickAddQuestionSourceChapterPopup } from "./quickaddquestionsourcechapterpopup/QuickAddQuestionSourceChapterPopup";
import { RequestType } from "../network/RequestType";

const QuickAddQuestion = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const data = location.state;
  const questionReducer = useSelector((state) => state.courseReducer);
  const [filterQuestion, setFilterQuestion] = useState("");
  const apiState = useSelector((state) => state.apiStateReducer);
  const [showLoadingEntireQuestion, setShowLoadingEntireQuestion] =
    useState(false);
  const [showLoadingSelectedQuestion, setShowLoadingSelectedQuestion] =
    useState(false);
  const [sourceChapterId, setSourceChapterId] = useState("");
  const [
    quickAddQuestionSourceChapterPopupProps,
    setQuickAddQuestionSourceChapterPopupProps,
  ] = useState({
    show: false,
  });

  useEffect(() => {
    return () => {
      dispatch({
        type: ActionType.RESET_GET_ALL_QUESTION,
        payload: {},
      });
    };
  }, []);

  if (apiState.showSnackBar) {
    setTimeout(() => {
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: false,
          snackBarMessage: "",
          snackBarSeverity: "",
          apiError: false,
        },
      });
    }, 5000);
  }

  //? handle search for question
  const handleQuestionSearch = () => {
    if (filterQuestion !== undefined && filterQuestion !== "") {
      dispatch({
        type: ActionType.RESET_GET_ALL_QUESTION,
        payload: {},
      });
      dispatch(
        makeNetworkCallGetAllQuestion(
          filterQuestion,
          data?.chapterId,
          "",
          NetworkConstant.getAllQuestions,
          {},
          {}
        )
      );
    }
  };

  // handle choose chapter
  const handleChooseChapter = () => {
    setQuickAddQuestionSourceChapterPopupProps({
      show: true,
      onCancelQuickAddQuestionSourceChapterPopup: () =>
        setQuickAddQuestionSourceChapterPopupProps({
          show: false,
        }),
      onResponseOfQuickAddQuestionSourceChapterPopup: (response) => {
        setSourceChapterId(response);
        setQuickAddQuestionSourceChapterPopupProps({
          show: false,
        });
        setShowLoadingEntireQuestion(false);
        setShowLoadingSelectedQuestion(false);
      },
    });
  };

  //? handle copy questions click
  const handleCopyQuestionsClick = () => {
    let questionIdList = [];
    for (
      var i = 0;
      i <
      questionReducer?.getAllQuestion?.responseData?.questionResponse?.length;
      i++
    ) {
      if (
        questionReducer?.getAllQuestion?.responseData?.questionResponse?.[i]
          ?.isSelected !== undefined &&
        questionReducer?.getAllQuestion?.responseData?.questionResponse?.[i]
          ?.isSelected
      ) {
        questionIdList.push(
          questionReducer?.getAllQuestion?.responseData?.questionResponse?.[i]
            .questionId
        );
      }
    }
    uploadQuestion(questionIdList);
  };

  //? handle copy questions click for bulk upload
  const handleCopyQuestionsClickForEntireQuestions = () => {
    let questionIdList = [];
    for (
      var i = 0;
      i <
      questionReducer?.getAllQuestion?.responseData?.questionResponse?.length;
      i++
    ) {
      questionIdList.push(
        questionReducer?.getAllQuestion?.responseData?.questionResponse?.[i]
          .questionId
      );
    }
    uploadQuestion(questionIdList);
  };

  //  upload question
  const uploadQuestion = (questionIdList, ) => {
    const addQuestionsRequest = {
      chapterId: data?.chapterId,
      questionIds: questionIdList,
    };
    dispatch(
      makeNetworkCallForAddQuestions(
        RequestType.POST,
        NetworkConstant.addQuestions,
        addQuestionsRequest,
        {}
      )
    );
  };

   // handle response for add questions
   useEffect(() => {
    if (questionReducer.addQuestions.statusCode === 200) {
      dispatch({
        type: ActionType.RESET_ADD_QUESTIONS,
        payload: {},
      });
    }
  }, [questionReducer.addQuestions.statusCode]);

  //? handle checkbox
  const handleToggle = (value) => () => {
    const questionReducerCopy = { ...questionReducer?.getAllQuestion };
    const updatedQuestionData =
      questionReducerCopy?.responseData?.questionResponse[value];
    if (updatedQuestionData) {
      if (updatedQuestionData.isSelected === undefined) {
        updatedQuestionData.isSelected = true;
      } else {
        updatedQuestionData.isSelected = !updatedQuestionData.isSelected;
      }
    }
  };

  // handle edit question click
  //   const handleEditQuestionClick = (questionId) => {
  //     dispatch(
  //       makeNetworkCallGetQuestionById(
  //         questionId,
  //         NetworkConstant.getQuestionByQuestionId,
  //         {},
  //         {}
  //       )
  //     );
  //   };

  // handle api response of get question by id
  //   useEffect(() => {
  //     if (questionReducer.getQuestionById?.statusCode === 200) {
  //       setDataStoreItem(DataStoreKey.CHAPTER_NAME, "");
  //       handleEditQuestion(questionReducer.getQuestionById?.responseData[0]);
  //       dispatch({
  //         type: ActionType.RESET_GET_QUESTION_BY_ID,
  //         payload: {},
  //       });
  //     }
  //   }, [questionReducer.getQuestionById?.statusCode]);

  //? handle edit question
  //   const handleEditQuestion = (questionData) => {
  //     setEditQuestionPopupProps({
  //       show: true,
  //       questionData: questionData,
  //       onCancelClick: () => setEditQuestionPopupProps({ show: false }),
  //       onUpdateQuestionResponse: (response) => {
  //         setEditQuestionPopupProps({ show: false });
  //       },
  //     });
  //   };

  return (
    <>
      <div className="quick-add-question-container">
        <TextField
          error={false}
          inputProps={inputPropsStyle.inputProp}
          id="outlined-error-helper-text"
          label={<span>Search</span>}
          rows={20}
          value={filterQuestion}
          onChange={(event) => setFilterQuestion(event.target.value)}
          InputProps={{
            endAdornment: (
              <IconButton edge="end" onClick={handleQuestionSearch}>
                <ManageSearchIcon />
              </IconButton>
            ),
          }}
        />
        <Stack
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "20px",
          }}
          direction="row"
          spacing={1}
        >
          <LoadingButton variant="contained" onClick={handleChooseChapter}>
            <span>Choose chapter</span>
          </LoadingButton>
        </Stack>
        {apiState.showLoader && <BlueLoader />}
        <div className="action-container">
          {
            <span className="total-no-of-question">
              Total no of questions :{" "}
              {
                questionReducer?.getAllQuestion?.responseData?.questionResponse
                  ?.length
              }
            </span>
          }
          <Stack
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
            }}
            direction="row"
            spacing={1}
          >
            <LoadingButton
              loading={showLoadingEntireQuestion}
              loadingPosition="start"
              startIcon={<CloudUploadIcon />}
              variant="contained"
              onClick={handleCopyQuestionsClickForEntireQuestions}
            >
              <span>Upload entire question</span>
            </LoadingButton>
          </Stack>
          <Stack
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
            }}
            direction="row"
            spacing={1}
          >
            <LoadingButton
              loading={showLoadingSelectedQuestion}
              loadingPosition="start"
              startIcon={<CloudUploadIcon />}
              variant="contained"
              onClick={handleCopyQuestionsClick}
            >
              <span>Upload selected question</span>
            </LoadingButton>
          </Stack>
        </div>
        {questionReducer?.getAllQuestion?.responseData?.questionResponse?.map(
          (questionData, key) => {
            return (
              <div key={key} className="question-container-column">
                <div className="question-container">
                  <span>
                    {key + 1}.) {questionData?.questionName}
                  </span>
                  <Checkbox
                    edge="end"
                    onChange={handleToggle(key)}
                    checked={questionData?.isSelected}
                  />
                </div>
                {/* <Tooltip
                  title="Edit Question"
                  arrow
                  style={{
                    alignSelf: "flex-end",
                  }}
                >
                  <Fab
                    size="small"
                    color="primary"
                    aria-label="edit question"
                    onClick={() => {
                      handleEditQuestionClick(questionData?.questionId);
                    }}
                  >
                    <EditIcon />
                  </Fab>
                </Tooltip> */}
              </div>
            );
          }
        )}
      </div>
      <QuickAddQuestionSourceChapterPopup
        {...quickAddQuestionSourceChapterPopupProps}
      />
      {/* <EditQuestionPopup {...editQuestionPopupProps} /> */}
    </>
  );
};

export default QuickAddQuestion;
